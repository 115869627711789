/* eslint-disable react/react-in-jsx-scope */
import React, { useState, useEffect } from 'react';
import ProjectCard from '../components/ProjectCard';
import ProjectsNavbar from '../components/ProjectsNavbar';
import { styled } from '@mui/material/styles';
import { projects as projectsData } from '../data';
import { Category } from '../types';
import { motion } from 'framer-motion';
import { fadeInUp, stager, routeAnimation } from '../animations';
import Head from 'next/head';
// eslint-disable-next-line no-unused-vars
import * as Scroll from 'react-scroll';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useManagingState } from './contexts/StateContext';

interface StyledFormControlLabelProps extends FormControlLabelProps {
    checked: boolean;
}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => (
    <FormControlLabel {...props} />
))(({ theme, checked }) => ({
    '.MuiFormControlLabel-label': checked && {
        color: 'dark:text-white text-dark-100',
    },
}));

const MyFormControlLabel = (props: FormControlLabelProps) => {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
        checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
};

const Index = () => {
    const { fiterSeacrh, setFilterSearch } = useManagingState(); // eslint-disable-line
    const [projects, setProjects] = useState(projectsData);

    useEffect(() => {
        active !== 'all' && fiterSeacrh !== 'item' && setActive('all');
        setProjects(projectsData);
    }, [fiterSeacrh]);

    const [active, setActive] = useState('all');
    const [showDetail, setShowDetail] = useState<number | null>(null);

    const handlerFilterCategory = (category: Category | 'all') => {
        if (category === 'all') {
            setProjects(projectsData);
            setActive(category);
            return;
        }
        const newArray = projectsData.filter((project) => project.category.includes(category));
        setProjects(newArray);
        setActive(category);
    };

    const projectDesc = () => {
        const card = document.getElementById('overflow-y-scroll');
        card.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const [filterRadio, setFilterRadio] = useState<string>('name');
    const search = new RegExp(fiterSeacrh, 'i');

    return (
        <>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, shrink-to-fit=no"
                />
                <title>Portfolio Daniel Cela 👨‍💻– Software Engineer</title>
            </Head>
            <ProjectsNavbar handlerFilterCategory={handlerFilterCategory} active={active} />
            <div className="filterCustomize">
                <div className="flex items-center justify-center options_search_filter">
                    <span className="mr-4 dark:text-white text-dark-500">Filter by: </span>
                    <RadioGroup
                        row
                        name="use-radio-group"
                        defaultValue="project_name"
                        className="radioGroupCustome"
                    >
                        <MyFormControlLabel
                            value="project_name"
                            label="Project Name"
                            control={<Radio color="success" />}
                            onClick={() => {
                                setFilterRadio('name');
                            }}
                        />
                        <MyFormControlLabel
                            value="Technologies"
                            label="Technologies"
                            control={<Radio color="success" />}
                            onClick={() => {
                                setFilterRadio('key_techs');
                            }}
                        />
                    </RadioGroup>
                </div>
            </div>
            <motion.div
                id="overflow-y-scroll"
                className="h-full px-5 py-2 overflow-y-scroll"
                variants={routeAnimation}
                initial="initial"
                animate="animate"
                exit="exit"
            >
                <motion.div
                    className="relative grid grid-cols-12 gap-4 my-3"
                    variants={stager}
                    initial="initial"
                    animate="animate"
                    style={{ marginTop: '0px' }}
                >
                    {projects
                        .filter((item) =>
                            fiterSeacrh === 'item' || fiterSeacrh === 'all' || fiterSeacrh === ''
                                ? item
                                : search.test(item[filterRadio])
                        )
                        .map((project) => (
                            <motion.div
                                variants={fadeInUp}
                                className="col-span-12 overflow-hidden bg-gray-200 border-2 border-white rounded-lg cursor sm:col-span-6 lg:col-span-3 dark:bg-dark-200 dark:hover:border-green hover:border-dark-200 card-custome pointer-custome-p dark:border-dark-200"
                                key={project.name}
                                onClick={() => {
                                    projectDesc();
                                }}
                            >
                                <ProjectCard
                                    project={project}
                                    showDetail={showDetail}
                                    setShowDetail={setShowDetail}
                                />
                            </motion.div>
                        ))}
                </motion.div>
            </motion.div>
        </>
    );
};

export default Index;
