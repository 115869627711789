/* eslint-disable no-unused-vars */
import { RiComputerLine } from 'react-icons/ri';
import { FaServer } from 'react-icons/fa';
import { AiOutlineAntDesign, AiOutlineApi } from 'react-icons/ai';
import { MdDeveloperMode } from 'react-icons/md';
import { IProject, Service, Skill } from './types';
import { BsCircleFill } from 'react-icons/bs';
import { v4 as uuidv4 } from 'uuid';

export const services: Service[] = [
    {
        Icon: RiComputerLine,
        title: 'Frontend Development',
        about: 'I can build a beautiful and scalable SPA using <b> HTML</b>,<b>CSS</b>   and <b>React.js</b>, and not only. ',
    },
    {
        Icon: FaServer,
        title: 'Backend  Development',
        about: 'Handle database, server, api using popular frameworks.',
    },
    {
        Icon: AiOutlineApi,
        title: 'API Development',
        about: 'I can develop robust REST API ',
    },
    {
        Icon: MdDeveloperMode,
        title: 'Competitive Coder',
        about: 'A daily problem solver .',
    },
    // {
    //     Icon: AiOutlineAntDesign,
    //     title: "UI/UX designer",
    //     about:
    //         "Stunning user interface designer using <b>Figma</b>  and  <b>Framer</b> ",
    // },
    {
        Icon: RiComputerLine,
        title: 'SEO',
        about: 'I can upgrade web speed, search engine optimisation, and more.',
    },
];

export const languages: Skill[] = [
    // {
    //     Icon: BsCircleFill,
    //     name: "Python",
    //     level: "45",
    // },
    {
        Icon: BsCircleFill,
        name: 'Java Script',
        level: '90',
    },
    // {
    //     Icon: BsCircleFill,
    //     name: "React Native",
    //     level: "80",
    // },
    {
        Icon: BsCircleFill,
        name: 'React',
        level: '90',
    },
    {
        Icon: BsCircleFill,
        name: 'Vue',
        level: '70',
    },
    {
        Icon: BsCircleFill,
        name: 'Next',
        level: '90',
    },
    {
        Icon: BsCircleFill,
        name: 'Nuxt',
        level: '70',
    },
    {
        Icon: BsCircleFill,
        name: 'CodeIgniter',
        level: '90',
    },
    {
        Icon: BsCircleFill,
        name: 'Php',
        level: '90',
    },
    {
        Icon: BsCircleFill,
        name: 'Laravel',
        level: '70',
    },
    {
        Icon: BsCircleFill,
        name: 'Bootstrap',
        level: '100',
    },
];

export const tools: Skill[] = [
    // {
    //     Icon: BsCircleFill,
    //     name: "Figma",
    //     level: "85",
    // },
    {
        Icon: BsCircleFill,
        name: 'Photoshop',
        level: '80',
    },
    // {
    //     Icon: BsCircleFill,
    //     name: "Illustrator",
    //     level: "60",
    // },
    // {
    //     Icon: BsCircleFill,
    //     name: "Framer",
    //     level: "45",
    // },
];

export const projects: IProject[] = [
    {
        id: uuidv4(),
        name: 'Horse Rider',
        description: '',
        image_path: '/images/horserider.png',
        deployed_url: 'https://horse-rider.netlify.app',
        github_url: '#',
        category: ['react'],
        key_techs: ["React","React 3 Fiber", "Mui", "Mui Themes","reCAPTCHA"],
        button: true,
    },
    {
        id: uuidv4(),
        name: 'Paramashop E-commerce',
        description:
            '',
        image_path: '/images/paramashop.jpg',
        deployed_url: 'https://www.paramashop.it',
        github_url: '#',
        category: ['php'],
        key_techs: ['Shopware', 'Php'],
        button: true,
    },
    {
        id: uuidv4(),
        name: 'Deghi E-commerce',
        description: '',
        image_path: '/images/deghi.jpg',
        deployed_url: 'https://www.deghi.it',
        github_url: '#',
        category: ['vue'],
        key_techs: [
            'Vue',
            'Nuxt',
            'Vuex',
            'Typescript',
            'Nuxt Img',
            'Elastic Search',
            'Graph QL, Apollo',
            'Docker',
            'Kubernetes',
            'Pwa',
            'Twa',
            'Etc...',
        ],
        button: true,
    },
    {
        id: uuidv4(),
        name: 'CORE Scaffold',
        description: '',
        image_path: '/images/corescaffold.jpg',
        deployed_url: 'https://portal.corescaffold.com',
        github_url: '#',
        category: ['react'],
        key_techs: [
            'React',
            'Antd Design',
            'Redux',
            'Context Api',
            'Scss',
            'React Router',
            'Styled-Components',
            'React Hooks',
            'Ag-grid',
            'Etc...',
        ],
        button: true,
    },
    {
        id: uuidv4(),
        name: 'Portfolio',
        description: '',
        image_path: '/images/portpfolio_project.jpg',
        deployed_url: '#',
        github_url: 'https://github.com/DanielCela111/portofol_website',
        category: ['react'],
        key_techs: [
            'React',
            'Next.js',
            'Next Themes',
            'TypeScript',
            'Context Api',
            'Material Ui',
            'Tailwind Css',
            'Scss',
            'Post Css',
            'Framer Motion',
            'Google Analytics',
            'PWA',
        ],
        button: false,
    },
    {
        id: uuidv4(),
        name: 'COVID19 Tracker',
        description: '',
        image_path: '/images/covid_19_tracer.jpg',
        deployed_url: 'https://covid19-tracker.danielcela.com/',
        github_url: 'https://github.com/DanielCela111/COVID_Tracker',
        category: ['react'],
        key_techs: ['React', 'Chart.js', 'Material UI'],
        button: true,
    },
    {
        id: uuidv4(),
        name: 'Crwn Clothing',
        description: '',
        image_path: '/images/crwn-clothing.jpg',
        deployed_url: 'https://crwn-clothing.danielcela.com/',
        github_url: 'https://github.com/DanielCela111/crwn-clothing',
        category: ['react'],
        key_techs: [
            'React',
            'React Router',
            'Redux',
            'Redux Saga',
            'React Hooks',
            'Context API',
            'React Suspense + React Lazy',
            'Firebase',
            'Stripe API',
            'Styled-Components',
        ],
        button: true,
    },
    {
        id: uuidv4(),
        name: 'Career Tirana',
        description: '',
        image_path: '/images/karrieratirana.jpg',
        deployed_url: 'https://karriera.tirana.al/',
        github_url: '#',
        category: ['wordpress'],
        key_techs: ['Wordpress', 'html-css-js', 'jQuery'],
        button: true,
    },
    {
        id: uuidv4(),
        name: 'LMS Tirana',
        description: '',
        image_path: '/images/lmstirana.jpg',
        deployed_url: 'https://lms.tirana.al/',
        github_url: '#',
        category: ['codeigniter', 'php'],
        key_techs: ['Codeigniter', 'Php', 'Jquery', 'Javascript', 'mysql', 'MVC'],
        button: true,
    },

    {
        id: uuidv4(),
        name: 'CRM BillsOnline',
        description: '',
        image_path: '/images/billsonline.jpg',
        deployed_url: 'https://bills.al/online/',
        github_url: '#',
        category: ['codeigniter', 'php'],
        key_techs: ['Codeigniter', 'Php', 'Jquery', 'Javascript', 'DomPdf', 'mysql', 'MVC'],
        button: true,
    },
    {
        id: uuidv4(),
        name: 'Academy Of Investment',
        description: '',
        image_path: '/images/academy.jpg',
        deployed_url: 'https://academyofinvestment.com/',
        github_url: '#',
        category: ['html-css-js'],
        key_techs: ['html-css-js', 'jQuery', 'Gsap'],
        button: true,
    },
    {
        id: uuidv4(),
        name: 'AKSHI System Management for students',
        description: '',
        image_path: '/images/crmimage.jpg',
        deployed_url: '#',
        github_url: '#',
        category: ['laravel', 'php'],
        key_techs: ['Laravel', 'Blade php', 'Php', 'html-css-js', 'mysql', 'jQuery', 'MVC'],
        button: false,
    },
    {
        id: uuidv4(),
        name: 'Bills Al',
        description: '',
        image_path: '/images/bills.jpg',
        deployed_url: 'https://bills.al/',
        github_url: '#',
        category: ['wordpress'],
        key_techs: ['Wordpress', 'Wpbakery', 'Html-Css-Js', 'jQuery', 'ecommerce'],
        button: true,
    },
    {
        id: uuidv4(),
        name: 'Bnt Electronics',
        description: '',
        image_path: '/images/bntelectronics.jpg',
        deployed_url: 'https://bntelectronics.com/',
        github_url: '#',
        category: ['wordpress'],
        key_techs: ['Wordpress', 'Wpbakery', 'Html-Css-Js', 'jQuery'],
        button: true,
    },
    {
        id: uuidv4(),
        name: 'InfoSystem CRM',
        description: '',
        image_path: '/images/crm.jpg',
        deployed_url: '#',
        github_url: '#',
        category: ['laravel', 'php'],
        key_techs: ['Laravel', 'Blade php', 'Php', 'html-css-js', 'mysql', 'jQuery', 'MVC', 'scss'],
        button: false,
    },
    {
        id: uuidv4(),
        name: 'Attari Design',
        description: '',
        image_path: '/images/attaridesign.jpg',
        deployed_url: 'https://attaridesign.com/',
        github_url: '#',
        category: ['wordpress'],
        key_techs: ['Wordpress', 'Wpbakery', 'Html-Css-Js', 'jQuery'],
        button: true,
    },
    {
        id: uuidv4(),
        name: 'Arkevo Digital Agency',
        description: '',
        image_path: '/images/digital-agency.jpg',
        deployed_url: 'https://arkevo-group.com/',
        github_url: '#',
        category: ['html-css-js'],
        key_techs: ['html-css-js', 'jQuery'],
        button: true,
    },
    {
        id: uuidv4(),
        name: 'Monsters Rolodex',
        description: '',
        image_path: '/images/monsters_rolodex.jpg',
        deployed_url: 'https://monsters.danielcela.com/',
        github_url: 'https://github.com/DanielCela111/monsters-rolodex',
        category: ['react'],
        key_techs: ['React'],
        button: true,
    },
];
